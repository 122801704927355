<template>
	<v-layout class="btx-search-criteria">
		<v-flex md10>
			<h6>Search Criteria</h6>
			<ul>
				<li v-if="search">
					{{ searchString }} have a match for
					<span class="search-text">{{ search }}</span>
				</li>
				<li v-for="(field, index) in sortedFields" :key="index">
					{{ field.label }} {{ field.text }}
					<span class="search-text">{{ query[field.model] }}</span>
					<template v-if="sortedFields[index + 1]"> (and)</template>
				</li>
			</ul>
			<v-btn
				v-if="!isActivity"
				depressed
				color="blue darken-4"
				class="text-white"
				tile
				text
				v-on:click="$emit('advance-search', true)"
			>
				<v-icon left>mdi-magnify</v-icon>
				Advance Search
			</v-btn>
		</v-flex>
		<v-flex md2 class="text-right">
			<v-btn
				depressed
				color="red lighten-1"
				class="text-white"
				tile
				text
				small
				v-on:click="$emit('close-search', true)"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-flex>
	</v-layout>
</template>
<script>
import { filter } from "lodash";
export default {
	data() {
		return {};
	},
	props: {
		isActivity: {
			type: Boolean,
			default: false,
		},
		searchString: {
			type: String,
			default: null,
		},
		searchFields: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	computed: {
		search() {
			const { search } = this.$route.query;
			/* if(!search){
				this.$emit('close-search', true)
			} */
			return search;
		},
		query() {
			return this.$route.query;
		},
		sortedFields() {
			const _this = this;
			return filter(_this.searchFields, function (row) {
				return _this.query[row.model];
			});
		},
	},
};
</script>
