import {
	GET_LISTING,
	BULK_ACTION,
	UPDATE_TABLE,
	SET_SELECTED_ROWS,
	SET_SEARCH_MODEL,
	SET_SEARCH_TITLE,
	SET_SEARCH_BAR,
	SET_THEAD,
	BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { cloneDeep, orderBy, includes, isEmpty, identity, pickBy } from "lodash";
import { mapGetters } from "vuex";
import Draggable from "vuedraggable";
import Table from "@/view/components/Table";
import ExportDialog from "@/view/components/ExportDialog.vue";
import ExportDialogNew from "@/view/components/ExportDialogNew.vue";
import ImportDialog from "@/view/components/ImportDialog.vue";
import ImportDialogNew from "@/view/components/importDialogNew.vue";
import SearchCriteria from "@/view/components/SearchCriteria.vue";
import FilterDialog from "@/view/components/FilterTemplate.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	data() {
		return {
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			totalSelected: 0,
			paginationPageSize: 0,
			pageLoading: true,
			tempTheadShow: null,
			advanceSearch: false,
			filter: new Object(),
			btxQuery: new Object(),
			exportCurrentDialog: false,
			exportDialog: false,
			exportDialogNew: false,
			importDialogNew: false,
			importDialog: false,
			status: "all",
			pageTitle: null,
			pageBreadcrumbs: null,
			endpoint: null,
			internal: false,
			relation_type: null,
			relation_type_id: 0,
			defaultFilter: new Object(),
		};
	},
	methods: {
		resetSearch() {
			this.$router
				.replace({
					query: {
						status: this.status || "all",
						t: new Date().getTime(),
					},
				})
				.then(() => {
					this.btxQuery = new Object();
					this.status = "all";
					this.$store.dispatch(SET_SEARCH_MODEL, null);
				})
				.then(() => {
					this.getListing();
				});
		},
		doAction(key) {
			switch (key) {
				case "import":
					this.importDialog = true;
					break;
				case "export":
					this.exportDialog = true;
					break;
				case "export_current_view":
					this.exportCurrentDialog = true;
					break;
				case "mark_as_active":
					this.doBulkAction("mark_as_active", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "mark_as_inactive":
					this.doBulkAction("mark_as_inactive", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "delete_rows":
					this.doBulkAction("delete_rows", this.endpoint + "/bulk-action").then(() => {
						this.filterRows();
					});
					break;
				case "refresh":
					this.getListing();
					break;
			}
		},
		filterRows() {
			this.$router
				.replace({
					query: {
						...this.$route.query,
						...this.btxQuery,
						page: this.currentPage,
						status: this.status || "all",
						search: this.listingSearch || undefined,
						t: new Date().getTime(),
					},
				})
				.then(() => {
					this.getListing();
				});
		},
		doAdvanceSearch() {
			const btxQuery = cloneDeep(pickBy(this.btxQuery, identity));
			this.$router
				.replace({
					query: {
						...btxQuery,
						status: this.status || "all",
						search: undefined,
						t: new Date().getTime(),
					},
				})
				.then(() => {
					this.getListing();
					this.advanceSearch = false;
				});
		},
		doBulkAction(type, url) {
			const _this = this;
			_this.pageLoading = true;
			return new Promise((resolve, reject) => {
				_this.$store
					.dispatch(BULK_ACTION, {
						type,
						url,
						selected: _this.selected,
					})
					.then(() => {
						_this.clearSelection();
						this.$store.dispatch(SET_LOCAL_DB);
						resolve(true);
					})
					.catch((error) => {
						reject(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			});
		},
		clearSelection() {
			this.$store.commit(SET_SELECTED_ROWS, []);
		},
		getListing() {
			this.pageLoading = true;

			const relational = new Object({});

			if (this.internal) {
				relational["internal"] = 1;
				relational["type"] = this.relation_type;
				relational["type-id"] = this.relation_type_id;
			}

			const filter = new Object({
				status: "all",
				...this.$route.query,
				...this.defaultFilter,
				...relational,
				tz: new Date().getTime(),
			});
			this.$store
				.dispatch(GET_LISTING, { url: this.endpoint, filter })
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.clearSelection();
					this.pageLoading = false;
				});
		},
		updateTableVisiblity(type) {
			const draggableTheadShow = this.draggableTheadShow;
			let result = cloneDeep(this.thead);
			for (let i = 0; i < result.length; i++) {
				result[i].visible = true;
				if (!includes(draggableTheadShow, result[i].key)) {
					result[i].visible = false;
				}
			}
			result = orderBy(result, ["order"], ["asc"]);
			this.$store.commit(SET_THEAD, result);
			this.updateTable(type);
		},
		updateTable(type) {
			const params = new Object({
				tables: this.thead,
			});
			this.pageLoading = true;
			this.$store
				.dispatch(UPDATE_TABLE, { type, params })
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getFilters() {
			const query = this.$route.query;
			for (const field of this.listingFilter) {
				this.btxQuery[field.model] = query[field.model];
			}
			this.btxQuery = cloneDeep(pickBy(this.btxQuery, identity));
		},
		searchEnabled() {
			const btxQuery = cloneDeep(pickBy(this.btxQuery, identity));
			if (isEmpty(btxQuery) && !this.listingSearch) {
				return false;
			}
			return true;
		},
	},
	watch: {
		listingFilter: {
			deep: true,
			handler() {
				this.getFilters();
			},
		},
		listingSearch(param) {
			this.btxQuery = new Object();
			this.$router
				.replace({
					query: {
						status: this.status || "all",
						search: param || undefined,
						t: new Date().getTime(),
					},
				})
				.then(() => {
					this.getListing();
				});
		},
	},
	mounted() {
		this.getListing();
		if (!this.internal) {
			this.$store.dispatch(SET_SEARCH_BAR, true);
			this.$store.dispatch(SET_SEARCH_TITLE, this.pageTitle);
			this.$store.dispatch(SET_BREADCRUMB, this.pageBreadcrumbs);
		}
	},
	beforeDestroy() {
		this.$store.dispatch(BEFORE_DESTROY);
		if (!this.internal) {
			this.$store.dispatch(SET_BREADCRUMB, []);
		}
	},
	computed: {
		...mapGetters([
			"listingAction",
			"listingBulkAction",
			"listingStatus",
			"listingFilter",
			"listingSearch",
			"currentPage",
			"thead",
			"selected",
			"theadShow",
		]),
		draggableThead: {
			set(val) {
				let result = cloneDeep(val);
				for (let i = 0; i < result.length; i++) {
					if (!result[i].fixed) {
						result[i].order = i;
					}
				}
				result = orderBy(result, ["order"], ["asc"]);
				this.$store.commit(SET_THEAD, result);
			},
			get() {
				return orderBy(this.thead, ["order"], ["asc"]) || [];
			},
		},
		draggableTheadShow: {
			set(val) {
				this.tempTheadShow = cloneDeep(val);
			},
			get() {
				return this.tempTheadShow || this.theadShow || [];
			},
		},
	},
	components: {
		FilterDialog,
		ImportDialog,
		ExportDialog,
		ExportDialogNew,
		ImportDialogNew,
		Draggable,
		SearchCriteria,
		Table,
	},
};
