<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/comment-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Comments
			</v-flex>
		</v-layout>
		<div
			class="overflow-y"
			style="max-height: calc(100vh - 455px); min-height: calc(100vh - 455px)"
			:class="{ 'div-disabled': editWindow }"
		>
			<table v-if="comments.length" width="100%" class="detail-table">
				<template v-for="(row, index) in comments">
					<tr :key="`top-${index}`">
						<td class="px-3 pt-3 border-top-light-grey">
							<p class="m-0 blue--text font-level-1">{{ row.created_by }}</p>
						</td>
						<td class="px-3 pt-3 border-top-light-grey" align="right">
							<span class="text-muted font-small mr-3">{{ row.added_at }}</span>
							<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-on="on" v-bind="attrs" color="blue darken-4">mdi-dots-horizontal</v-icon>
								</template>

								<v-list>
									<v-list-item v-on:click="doAction(row, 'edit')">
										<v-list-item-title>
											<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
											Edit</v-list-item-title
										>
									</v-list-item>
									<v-list-item v-on:click="doAction(row, 'delete')" class="red--text text--lighten-1">
										<v-list-item-title class="red--text text--lighten-1">
											<v-icon small left color="red lighten-1">mdi-delete</v-icon>
											Delete</v-list-item-title
										>
									</v-list-item>
								</v-list>
							</v-menu>
						</td>
					</tr>
					<tr :key="`bottom-${index}`">
						<td colspan="2" class="px-3 pt-2 pb-3">
							<span class="text-muted font-level-1">{{ row.description }}</span>
						</td>
					</tr>
				</template>
			</table>
			<p v-else class="m-0 row-not-found text-center">
				<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
				Uhh... There are no comment at the moment.
			</p>
		</div>
		<v-form
			ref="commentForm"
			v-model.trim="formValid"
			lazy-validation
			v-on:submit.stop.prevent="onSubmit()"
		>
			<v-layout class="p-3 border-top-light-grey">
				<v-flex md9>
					<TextAreaInput
						:rows="4"
						dense
						ref="comment"
						hide-details
						v-model="description"
						placeholder="Enter Comment..."
						:disabled="pageLoading"
						:loading="pageLoading"
						:rules="[vrules.required(description, 'Comment')]"
					></TextAreaInput>
				</v-flex>
				<v-flex md3 class="ml-2 d-flex">
					<v-layout>
						<v-flex :md12="!editWindow" :md6="editWindow">
							<v-btn
								v-on:click="onSubmit()"
								:disabled="pageLoading"
								:loading="pageLoading"
								color="blue darken-4 w-100 text-white"
								depressed
								tile
								><v-icon small left>mdi-playlist-plus</v-icon>Save Comment</v-btn
							>
						</v-flex>
						<v-flex md6 v-if="editWindow" class="ml-3">
							<v-btn
								v-on:click="doAction(null, 'cancel-edit')"
								:disabled="pageLoading"
								:loading="pageLoading"
								color="blue darken-4 w-100 text-white"
								depressed
								tile
								><v-icon small left>mdi-playlist-remove</v-icon>Cancel</v-btn
							>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-form>
		<Dialog :dialog="deleteDialog" :dialog-width="600">
			<template v-slot:title> Delete Comment</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">Deleting Comment is irreversible, Are you sure about deleting it?</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteLoading"
					:disabled="deleteLoading"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteComment()"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteLoading" v-on:click="deleteDialog = false">
					No, Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import TextAreaInput from "@/view/components/TextAreaInput";
import Dialog from "@/view/components/Dialog";

export default {
	name: "comment-template",
	title: "Comment Template",
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: true,
			deleteLoading: false,
			deleteDialog: false,
			editWindow: false,
			uuid: null,
			description: null,
			comments: [],
		};
	},
	watch: {
		typeUuid(param) {
			console.log(param);
			this.getComments();
		},
	},
	components: {
		Dialog,
		TextAreaInput,
	},
	methods: {
		init() {
			this.uuid = null;
			this.description = null;
			this.editWindow = false;
			this.deleteDialog = false;
		},
		doAction(row, param) {
			switch (param) {
				case "cancel-edit":
					this.init();
					break;
				case "edit":
					this.uuid = row.uuid;
					this.description = row.description;
					this.editWindow = true;
					this.$nextTick(() => {
						this.$refs["comment"].$refs["input-text-area"].$refs["input"].focus();
					});
					break;
				case "delete":
					this.uuid = row.uuid;
					this.deleteDialog = true;
					break;
			}
		},
		getComments() {
			ApiService.get(`${this.type}/${this.typeUuid}/comment`)
				.then(({ data }) => {
					this.comments = data;
					this.init();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		deleteComment() {
			if (!this.uuid) {
				return false;
			}
			this.deleteLoading = true;
			ApiService.delete(`${this.type}/${this.typeUuid}/comment/${this.uuid}`)
				.then(() => {
					this.getComments();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.deleteLoading = false;
				});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.commentForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.commentForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			let APIURL = `${this.type}/${this.typeUuid}/comment`;
			if (this.uuid) {
				APIURL = `${this.type}/${this.typeUuid}/comment/${this.uuid}`;
			}

			ApiService.patch(APIURL, { description: this.description })
				.then(() => {
					this.getComments();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getComments();
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
