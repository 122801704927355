<template>
	<div class="address-book px-3 py-3 overflow-y" style="max-height: calc(100vh - 285px)">
		<template v-if="contentLoaded">
			<v-row>
				<v-col v-for="(row, index) in addressList" :key="index" md="4" class="py-3">
					<div class="p-4 border-light-grey" style="min-height: 226px">
						<v-layout>
							<v-flex md10 class="d-flex align-center">
								<p class="m-0 font-level-2-bold mr-3">Address #{{ row.barcode }}</p>
							</v-flex>
							<v-flex md2 class="text-right">
								<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-on="on" v-bind="attrs" color="blue darken-4">mdi-dots-vertical</v-icon>
									</template>

									<v-list>
										<v-list-item v-if="getPermission('customer::edit')" v-on:click="initAddress(row, true)">
											<v-list-item-title>
												<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
												Edit</v-list-item-title
											>
										</v-list-item>
										<v-list-item
											v-if="getPermission('customer::edit') && !row.default"
											v-on:click="deleteAddress(row, true)"
											class="red--text text--lighten-1"
										>
											<v-list-item-title class="red--text text--lighten-1">
												<v-icon small left color="red lighten-1">mdi-delete</v-icon>
												Delete</v-list-item-title
											>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-flex>
						</v-layout>
						<v-layout>
							<v-flex md1>
								<v-icon color="blue darken-4">mdi-map-marker-outline</v-icon>
							</v-flex>
							<v-flex md11>
								<p class="m-0">
									<ShowValue :object="row" object-key="name" label="display name"></ShowValue>,
								</p>
								<p class="m-0">
									<ShowValue :object="row" object-key="company_name" label="company name"></ShowValue>,
								</p>
								<p class="m-0">
									<ShowValue :object="row" object-key="phone_no" label="phone no"></ShowValue>,
								</p>
								<p class="m-0"><ShowValue :object="row" object-key="email" label="email"></ShowValue>,</p>
								<p class="m-0">
									<ShowValue :object="row" object-key="address_line_1" label="street 1"></ShowValue>,
								</p>
								<p v-if="false" class="m-0">
									<ShowValue :object="row" object-key="address_line_2" label="street 2"></ShowValue>,
								</p>
								<!-- <p class="m-0">
									<ShowValue :object="row" object-key="address_city" label="city"></ShowValue>,
								</p> -->
								<p v-if="false" class="m-0">
									<!-- <ShowValue :object="row" object-key="address_state" label="state"></ShowValue>, -->
									<ShowValue :object="row" object-key="address_postal_code" label="postal code"></ShowValue>,
								</p>
								<p v-if="false">
									<ShowValue :object="row" object-key="address_country" label="country"></ShowValue>
								</p>
								<v-chip
									v-if="row.default"
									color="light-blue lighten-4 light-blue--text text--darken-4"
									label
									>DEFAULT</v-chip
								>
							</v-flex>
						</v-layout>
					</div>
				</v-col>
			</v-row>

			<Dialog :dialog="addressDialog" :dialog-width="dialogWidth">
				<template v-slot:title>
					<template v-if="newAddress"> Add New </template>
					<template v-else> Update </template>
					Billing Address
				</template>
				<template v-slot:body>
					<v-form
						ref="addressForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="onSubmit()"
					>
						<v-checkbox
							v-if="false"
							class="mx-3 mt-0 pt-0"
							hide-details
							dense
							v-model="address.default"
							:true-value="1"
							:false-value="0"
						>
							<template v-slot:label>
								Set as default
								<TooltipQuestion>
									<template v-slot:text
										>Address set as default will be<br />used as a Shipping Address</template
									>
								</TooltipQuestion>
							</template>
						</v-checkbox>
						<AddressTemplate
							is-member
							:contact-person-list="contactPersonList"
							:type="typeText == 'Customer' ? 2 : 1"
							:disabled="pageLoading"
							:uuid="typeUuid"
							:loading="pageLoading"
							v-model="address"
							v-on:updateContactPersonList="getContactPersons"
						>
						</AddressTemplate>
					</v-form>
				</template>
				<template v-slot:action>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="onSubmit()"
					>
						Save
					</v-btn>
					<v-btn
						depressed
						tile
						:disabled="pageLoading"
						v-on:click="
							$emit('close', true);
							addressDialog = false;
						"
					>
						Close
					</v-btn>
				</template>
			</Dialog>
			<DeleteTemplate
				type="Address"
				:delete-text="deleteText"
				v-on:success="
					deleteAddress({}, false);
					getAddress();
				"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
			>
			</DeleteTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { UpdateAddress, CreateAddress, GetAddress } from "@/core/lib/member.lib";
import Dialog from "@/view/components/Dialog";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import AddressTemplate from "@/view/components/Address";
import TooltipQuestion from "@/view/components/TooltipQuestion";

export default {
	name: "member-address-book",
	title: "Address Book Member",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		newAddress: {
			type: [String, Boolean],
			default: null,
		},
		contactPersonList: {
			type: Array,
			default: new Array(),
		},
	},
	data() {
		return {
			deleteText: null,
			deleteURL: null,
			formValid: true,
			contentLoaded: false,
			deleteDialog: false,
			addressDialog: false,
			pageLoading: false,
			addressList: [],
			address: {
				address_city: null,
				address_country: "Singapore",
				address_line_1: null,
				address_line_2: null,
				address_postal_code: null,
				address_state: null,
				default: 0,
				description: null,
				has_lat_long: 0,
				id_number: null,
				latitude: null,
				location: null,
				longitude: null,
				company_name: null,
				phone_no: null,
				email: null,
				name: null,
				uuid: null,
			},
		};
	},
	watch: {
		newAddress(param) {
			if (param) {
				this.initAddress({}, true);
			}
		},
	},
	methods: {
		getContactPersons() {
			this.$emit("updateContactPersonList", true);
		},
		getAddress() {
			GetAddress(this.typeUuid, this.type)
				.then((data) => {
					this.addressList = data;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.contentLoaded = true;
				});
		},
		initAddress(row, dialog) {
			this.address.address_city = row.address_city ? row.address_city : null;
			this.address.address_country = row.address_country ? row.address_country : null;
			this.address.address_line_1 = row.address_line_1 ? row.address_line_1 : null;
			this.address.address_line_2 = row.address_line_2 ? row.address_line_2 : null;
			this.address.address_postal_code = row.address_postal_code ? row.address_postal_code : null;
			this.address.contact_person = row.contact_person ? row.contact_person : null;
			this.address.address_state = row.address_state ? row.address_state : null;
			this.address.default = row.default ? row.default : 0;
			this.address.description = null;
			this.address.has_lat_long = 0;
			this.address.id_number = null;
			this.address.latitude = null;
			this.address.location = null;
			this.address.longitude = null;
			this.address.company_name = row.company_name ? row.company_name : null;
			this.address.phone_no = row.phone_no ? row.phone_no : null;
			this.address.email = row.email ? row.email : null;
			this.address.name = row.name ? row.name : null;
			this.address.uuid = row.uuid ? row.uuid : null;

			this.$nextTick(() => {
				this.addressDialog = dialog;
			});
		},
		deleteAddress(row, dialog) {
			this.deleteText = row.uuid ? row.name : null;
			this.deleteURL = row.uuid ? `${this.type}/${this.typeUuid}/address/${row.uuid}` : null;
			this.deleteDialog = dialog;
		},
		async onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.addressForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.addressForm.validate()) {
				return false;
			}
			_this.address.address_type = 2;
			const formData = { address: [_this.address] };

			try {
				_this.pageLoading = true;

				if (formData.uuid) {
					await UpdateAddress(_this.typeUuid, _this.type, formData.uuid, formData);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Property has been updated." },
					]);
				} else {
					await CreateAddress(_this.typeUuid, _this.type, formData);

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Property has been created." },
					]);
				}
				_this.initAddress({}, false);
				_this.getAddress();
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	components: {
		Dialog,
		ShowValue,
		TooltipQuestion,
		DeleteTemplate,
		AddressTemplate,
	},
	mounted() {
		this.getAddress();
	},
	computed: {
		...mapGetters(["errors"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 65);
		},
	},
};
</script>
