<template>
	<v-sheet v-if="tbody.length > 0" :class="{ 'internal-table-listing': internal }">
		<template v-if="!pageLoading">
			<v-simple-table class="bt-table listing-table">
				<template v-slot:default>
					<thead>
						<tr>
							<th v-for="(th, key) in sortedThead" :key="key" :width="th.width">
								<v-layout>
									<v-flex class="text-left">
										<template v-if="th.checkbox">
											<v-checkbox
												dense
												v-if="getPermission(`${type}::edit`)"
												v-model="checkbox"
												hide-details
												class="m-0 p-0"
												v-on:change="selectAll"
												:indeterminate="indeterminate"
											></v-checkbox>
										</template>
										<template v-else>
											{{ th.name }}
										</template>
									</v-flex>
									<v-flex v-if="!th.checkbox && th.sortable" class="text-right ml-2">
										<template v-if="th.sort == 'ASC'"
											><v-icon small link>mdi-sort-ascending</v-icon></template
										>
										<template v-if="th.sort == 'DESC'"
											><v-icon small link>mdi-sort-descending</v-icon></template
										>
									</v-flex>
								</v-layout>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="(td, bkey) in tbody">
							<v-hover :key="`row-hover-${bkey}`" v-slot="{ hover }">
								<tr :key="`row-${bkey}`">
									<template v-for="(th, hkey) in sortedThead">
										<td
											:key="`first-${hkey}-${bkey}`"
											:width="th.width"
											v-on:click="routeToDetail(th.order, td.uuid)"
											:class="{ 'td-link': th.order > 1 }"
										>
											<template v-if="th.checkbox">
												<v-checkbox
													dense
													v-if="getPermission(`${type}::edit`)"
													v-model="selectedRows"
													:value="td.uuid"
													hide-details
													class="m-0 p-0"
													multiple
													v-on:change="updateSelected()"
												></v-checkbox>
											</template>
											<template v-else-if="th.key == 'action'">
												<template v-if="getPermission(`${type}::edit`)">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																fab
																:dark="!is_edit_disabled(td)"
																:disabled="is_edit_disabled(td)"
																x-small
																color="green"
																v-bind="attrs"
																v-on="on"
																class="mr-3"
																v-on:click.stop.prevent="routeToUpdate(td.uuid)"
															>
																<v-icon>mdi-pencil</v-icon>
															</v-btn>
														</template>
														<span>Edit {{ type }}</span>
													</v-tooltip>
												</template>
												<template v-if="getPermission(`${type}::delete`)">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-btn
																depressed
																fab
																dark
																x-small
																color="red"
																v-bind="attrs"
																v-on="on"
																class="mr-3"
																v-on:click.stop.prevent="deleteConfirm(td)"
															>
																<v-icon>mdi-delete</v-icon>
															</v-btn>
														</template>
														<span>Delete {{ type }}</span>
													</v-tooltip>
												</template>
												<template v-if="type == 'service'">
													<template v-if="getPermission('invoice::create')">
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	fab
																	:dark="!is_invoice_disabled(td)"
																	x-small
																	:disabled="is_invoice_disabled(td)"
																	color="blue darken-4"
																	v-bind="attrs"
																	v-on="on"
																	v-on:click.stop.prevent="convert_to_invoice(td)"
																>
																	<v-icon>mdi-file-refresh-outline</v-icon>
																</v-btn>
															</template>
															<span>Invoice</span>
														</v-tooltip>
													</template>
												</template>
												<template v-if="type == 'service'">
													<template v-if="getPermission(`${type}::edit`)">
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	fab
																	x-small
																	color="red"
																	:disabled="td.status == 4"
																	class="ml-3 text-light"
																	v-bind="attrs"
																	v-on="on"
																	v-on:click.stop.prevent="voidConfirm(td)"
																>
																	<v-icon>mdi-block-helper</v-icon>
																</v-btn>
															</template>
															<span>Void</span>
														</v-tooltip>
													</template>
												</template>
												<template v-if="type == 'invoice'">
													<template v-if="getPermission('payment::create')">
														<v-tooltip top content-class="custom-top-tooltip">
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	depressed
																	fab
																	:dark="!is_payment_disabled(td)"
																	x-small
																	:disabled="is_payment_disabled(td)"
																	color="blue darken-4"
																	v-bind="attrs"
																	v-on="on"
																	v-on:click.stop.prevent="convert_to_payment(td)"
																>
																	<v-icon>mdi-currency-usd-circle-outline</v-icon>
																</v-btn>
															</template>
															<span>Payment</span>
														</v-tooltip>
													</template>
												</template>
											</template>
											<template v-else-if="th.key == 'image'">
												<ImageTemplate circle style="max-width: 50px" :src="td.image"></ImageTemplate>
											</template>
											<template v-else-if="th.key == 'barcode'">
												<Chip :text="td.barcode" color="cyan"></Chip>
											</template>

											<template v-else-if="th.key == 'status'">
												<v-col md-12>
													<Status tooltip tooltip-text="Status" :status="td.status_value"></Status>
												</v-col>
											</template>

											<template v-else-if="th.key == 'invoice_detail'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<div>
														<Chip
															tooltip
															tooltip-text="Invoice #"
															small
															:text="td.barcode"
															color="blue darken-4"
														></Chip>
													</div>
													<div>
														<Status
															tooltip
															tooltip-text="Invoice Status"
															small
															:status="td.status_value"
															class="mt-1"
														></Status>
													</div>
													<div>
														<span class="bold-600 mr-1">Invoice Number:</span>
														<ShowValue :object="td" object-key="invoice_id" label="invoice number"></ShowValue>
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'payment_detail'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<Chip
														tooltip
														tooltip-text="Payment #"
														small
														:text="td.barcode"
														color="blue darken-4"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Payment Mode"
														small
														class="mt-1"
														:text="td.p_mode"
														color="amber accent-4"
													></Chip>
													<div class="mt-1">
														<span class="bold-600 mr-1">Amount Received:</span>
														<ShowValue
															:object="td"
															object-key="p_amount_formatted"
															label="amount received"
														></ShowValue
														><br />
														<span class="bold-600 mr-1">Payment Date:</span>
														<ShowValue
															:object="td"
															object-key="p_date_formatted"
															label="payment date"
														></ShowValue>
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'payment_invoice_detail'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<Chip
														tooltip
														tooltip-text="Invoice #"
														small
														:text="td.invoice_barcode"
														color="blue darken-4"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Invoice Status"
														small
														class="mt-1"
														:text="td.invoice_status_text"
														:color="td.invoice_status_color"
														:text-color="td.invoice_status_textcolor"
													></Chip>
													<div>
														<span class="bold-600 mr-1">Invoice Number:</span>
														<ShowValue
															:object="td"
															object-key="invoice_invoice_id"
															label="invoice number"
														></ShowValue>
													</div>
												</div>
											</template>

											<template v-else-if="th.key == 'invoice_approval'">
												<div class="d-flex align-center my-1" v-if="td.is_approved == 1">
													<div v-if="false">
														<ImageTemplate
															circle
															style="max-width: 50px"
															:src="td.approve_by_profile"
														></ImageTemplate>
													</div>
													<div class="ml-2">
														<p class="mb-0">
															<Chip
																tooltip
																tooltip-text="Approval Status"
																small
																:text="td.approve_status_formatted"
																:color="
																	td.approve_status == 1
																		? 'green lighten-1 white--text'
																		: 'red lighten-1 white--text'
																"
															></Chip>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Approved By:</span> {{ td.approve_by_name }}
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Approved At:</span> {{ td.approve_at_formatted }}
														</p>
													</div>
												</div>
												<div v-else class="text-center text--secondary">N/A</div>
											</template>

											<template v-else-if="th.key == 'service_detail'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<Chip
														tooltip
														tooltip-text="Service #"
														small
														:text="td.service_barcode"
														color="blue darken-4"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Service Type"
														small
														:text="td.service_type_formatted"
														class="mt-1"
														:color="td.service_type == 'on-site' ? 'brown darken-4' : 'blue-grey darken-4'"
													></Chip>
												</div>
											</template>

											<template v-else-if="th.key == 'service_expected_duration'">
												<div class="d-flex flex-column">
													<div>
														<span class="bold-600 mr-1">Start:</span
														><ShowValue
															:object="td"
															object-key="expected_start_date_formatted"
															label="Start Date (Expected)"
														></ShowValue>
													</div>
													<div>
														<span class="bold-600 mr-1">End:</span
														><ShowValue
															:object="td"
															object-key="expected_end_date_formatted"
															label="End Date (Expected)"
														></ShowValue>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'service_actual_duration'">
												<div class="d-flex flex-column" v-if="td.actual_start_date_formatted">
													<div>
														<span class="bold-600 mr-1">Start:</span
														><ShowValue
															:object="td"
															object-key="actual_start_date_formatted"
															label="Start Date (Actual)"
														></ShowValue>
													</div>
													<div>
														<span class="bold-600 mr-1">End:</span
														><ShowValue
															:object="td"
															object-key="actual_end_date_formatted"
															label="End Date (Actual)"
														></ShowValue>
													</div>
												</div>
												<div v-else class="text-center text--secondary">N/A</div>
											</template>
											<template v-else-if="th.key == 'service_type'">
												<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
													<Chip
														tooltip
														tooltip-text="Service Type"
														small
														:text="td.service_type_formatted"
														:color="td.service_type == 'on-site' ? 'brown darken-4' : 'blue-grey darken-4'"
													></Chip>
													<Chip
														tooltip
														tooltip-text="Invoiced"
														small
														v-if="td.is_invoiced == 1"
														class="mt-1"
														text="Invoiced"
														color="orange darken-2"
													></Chip>
													<Status
														tooltip
														tooltip-text="Status"
														small
														:status="td.status_value"
														class="mt-1"
													></Status>
												</div>
											</template>
											<template v-else-if="th.key == 'asset_service'">
												<div class="d-flex align-center">
													<div v-if="false">
														<ImageTemplate circle style="max-width: 50px" :src="td.asset_image"></ImageTemplate>
													</div>
													<div class="ml-2">
														<p v-if="false" class="mb-0">
															<Chip
																tooltip
																tooltip-text="Asset #"
																small
																:text="td.asset_barcode"
																color="blue darken-4"
															></Chip>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Asset Name:</span
															><ShowValue :object="td" object-key="asset_name" label="asset name"></ShowValue>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Asset Id:</span
															><ShowValue :object="td" object-key="asset_id" label="asset id"></ShowValue>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Cost Center:</span
															><ShowValue
																:object="td"
																object-key="asset_cost_center"
																label="cost center"
															></ShowValue>
														</p>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'acknowledgement'">
												<div class="d-flex align-center my-1" v-if="td.is_acknowledged == 1">
													<div v-if="false">
														<ImageTemplate
															circle
															style="max-width: 50px"
															:src="td.acknowledge_by_profile"
														></ImageTemplate>
													</div>
													<div class="ml-2">
														<p class="mb-0">
															<Chip
																tooltip
																tooltip-text="Acknowledge Status"
																small
																:text="td.acknowledge_status_formatted"
																:color="
																	td.acknowledge_status == 1
																		? 'green lighten-1 white--text'
																		: 'red lighten-1 white--text'
																"
															></Chip>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Acknowledge By:</span>{{ td.acknowledge_by_name }}
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Acknowledge At:</span>{{ td.acknowledge_at_formatted }}
														</p>
													</div>
												</div>
												<div v-else class="text-center text--secondary">N/A</div>
											</template>
											<template v-else-if="th.key == 'service_performed_by'">
												<div class="d-flex align-center">
													<div v-if="false">
														<ImageTemplate
															circle
															style="max-width: 50px"
															:src="td.perfomed_by_profile"
														></ImageTemplate>
													</div>
													<div class="ml-2">
														<p class="mb-0">
															<Chip
																tooltip
																tooltip-text="Service By"
																small
																:text="td.service_by_formatted"
																color="blue darken-4 white--text"
															></Chip>
														</p>
														<p class="mb-0">
															<span class="bold-600 mr-1">Service By:</span>{{ td.perfomed_by_display_name }}
														</p>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'created_at'">
												<Chip
													tooltip
													tooltip-text="Created By"
													small
													:text="td.created_by"
													color="blue darken-4 white--text"
													class="mb-1"
												></Chip>
												<TableDateTime
													small
													v-if="td.created_at"
													:human-format="td.created_at"
													:system-format="td.added_at"
												></TableDateTime>
											</template>
											<template v-else-if="th.key == 'modified_at'">
												<Chip
													tooltip
													tooltip-text="Modified By"
													small
													:text="td.modified_by"
													color="blue darken-4 white--text"
													class="mb-1"
												></Chip>
												<TableDateTime
													small
													v-if="td.modified_at"
													:human-format="td.modified_at"
													:system-format="td.updated_at"
												></TableDateTime>
												<span v-else>-</span>
											</template>
											<template v-else>
												<ShowValue :object="td" :object-key="th.key" :label="th.name"></ShowValue>
											</template>
										</td>
									</template>
								</tr>
							</v-hover>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<v-row class="my-2">
				<v-col md="6" class="my-auto">
					<p class="m-0">{{ showingString }}</p>
				</v-col>
				<v-col md="6" class="text-right">
					<v-pagination
						color="blue darken-4"
						v-model="page"
						:length="totalPage"
						total-visible="9"
						v-on:input="updatePagination($event)"
					></v-pagination>
				</v-col>
			</v-row>
		</template>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete {{ type }}</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">{{ type }} </span>
							<span class="red--text font-bolder lighten-1">#{{ deleteText }}</span>
							will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
					<v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em>Note: {{ deleteNote }}</em>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteButton"
					:disabled="deleteButton"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteRow"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteButton" v-on:click="deleteClear"> No, Close </v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="voidDialog" :dialog-width="640">
			<template v-slot:title> Void {{ type }}</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">{{ type }} </span>
							<span class="red--text font-bolder lighten-1">#{{ voidText }}</span>
							will be void forever and cannot be updated later. Are you sure about void it?
						</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="voidButton"
					:disabled="voidButton"
					depressed
					color="red lighten-1"
					tile
					v-on:click="voidRow"
				>
					Yes! Void
				</v-btn>
				<v-btn depressed tile :disabled="voidButton" v-on:click="voidClear"> No, Close </v-btn>
			</template>
		</Dialog>
	</v-sheet>
	<v-sheet v-else>
		<p class="m-0 row-not-found text-center">
			<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
			Uhh... There are no {{ type }} at the moment.
		</p>
	</v-sheet>
</template>
<script>
import { toSafeInteger, isArray } from "lodash";
import { mapGetters } from "vuex";
import { SET_SELECTED_ROWS, SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
import Chip from "@/view/components/Chip";
import Status from "@/view/components/Status";
import Dialog from "@/view/components/Dialog";
import TableDateTime from "@/view/components/TableDateTime";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import ImageTemplate from "@/view/components/Image";
import { CART_COUNT } from "@/core/services/store/config.module";
import { getToken } from "@/core/services/jwt.service.js";

export default {
	name: "table-template",
	props: {
		type: {
			type: String,
			default: null,
		},
		deleteEndpoint: {
			type: String,
			default: null,
		},
		detailRoute: {
			type: String,
			default: null,
		},
		updateRoute: {
			type: String,
			default: null,
		},
		deleteNote: {
			type: String,
			default: null,
		},
		dialogUpdate: {
			type: Boolean,
			default: false,
		},
		disableEdit: {
			type: Boolean,
			default: false,
		},
		dialogView: {
			type: Boolean,
			default: false,
		},
		internal: {
			type: Boolean,
			default: false,
		},
		pageLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkbox: false,
			indeterminate: false,
			selectedRows: [],
			deleteDialog: false,
			voidDialog: false,
			deleteButton: false,
			voidButton: false,
			deleteUUID: null,
			deleteURL: null,
			deleteText: null,
			voidText: null,
			voidUUID: null,
			page: 1,
		};
	},
	watch: {
		currentPage(param) {
			this.page = param;
		},
		selected(param) {
			if (param.length < 1) {
				this.checkbox = false;
				this.indeterminate = false;
				this.selectedRows = [];
			}
		},
	},
	methods: {
		is_invoice_disabled(row) {
			if (this.type == "service") {
				if (row.is_invoiced == 1) {
					return true;
				}
				if (row.is_acknowledged == 1) {
					if (row.acknowledge_status == 1) {
						return false;
					}
				}
			}
			return true;
		},
		convert_to_invoice(row) {
			this.$router.push({
				name: "invoice-create",
				query: { service: row.uuid, asset: row.asset_uuid, t: new Date().getTime() },
			});
		},
		is_payment_disabled(row) {
			if (this.type == "invoice") {
				if (row.is_paid == 1) {
					return true;
				}
				if (row.is_approved == 1) {
					if (row.approve_status == 1) {
						return false;
					}
				}
			}
			return true;
		},
		convert_to_payment(row) {
			this.$router.push({
				name: "payment-create",
				query: { invoice: row.uuid, t: new Date().getTime() },
			});
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.$emit("reload:content", true);
			});
		},
		downloadFile(row) {
			window.open(row.url, "_blank");
		},
		downloadPdf(param, type) {
			if (type == "order-receive-pdf") {
				if (!param.has_checkin_pdf) {
					return false;
				}
			}
			if (type == "order-issue-pdf") {
				if (!param.has_checkout_pdf) {
					return false;
				}
			}
			if (type == "order-retire-pdf") {
				if (!param.has_retire_pdf) {
					return false;
				}
			}

			const token = getToken();
			const url = `${this.$apiURL()}${type}/${param.id}/download?token=${token}`;
			window.open(url, "_blank");
		},
		routeToDetail(order, uuid) {
			if (order < 2) {
				return false;
			}
			if (!this.dialogView) {
				if (this.detailRoute) {
					if (this.internal) {
						let routeData = this.$router.resolve({
							name: this.detailRoute,
							params: { uuid },
							query: { t: new Date().getTime() },
						});
						window.open(routeData.href, "_blank");
					} else {
						this.$router.push({
							name: this.detailRoute,
							params: { uuid },
							query: { t: new Date().getTime() },
						});
					}
				}
			} else {
				this.$emit("view:dialog", uuid);
				return false;
			}
		},
		routeToUpdate(uuid) {
			if (this.dialogUpdate) {
				this.$emit("update:dialog", uuid);
				return false;
			}
			this.$router.push({
				name: this.updateRoute,
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},
		deleteClear() {
			this.deleteDialog = false;
			this.deleteUUID = null;
			this.deleteURL = null;
			this.deleteText = null;
		},
		voidClear() {
			this.voidDialog = false;
			this.voidUUID = null;
			this.voidText = null;
		},
		deleteRow() {
			this.deleteButton = true;
			ApiService.delete(this.deleteURL)
				.then(() => {
					this.$emit("reload:content");
					this.$store.dispatch(CART_COUNT);
					this.deleteClear();
				})
				.catch(({ response }) => {
					console.log({ response });
				})
				.finally(() => {
					this.deleteButton = false;
				});
		},
		voidRow() {
			this.voidButton = true;
			ApiService.patch(`asset/service/${this.voidUUID}/void`)
				.then(() => {
					this.$emit("reload:content");
					this.$store.dispatch(CART_COUNT);
					this.voidClear();
				})
				.catch(({ response }) => {
					console.log({ response });
				})
				.finally(() => {
					this.voidButton = false;
				});
		},
		deleteConfirm({ uuid, barcode }) {
			this.deleteDialog = true;
			this.deleteUUID = uuid;
			this.deleteURL = this.deleteEndpoint + uuid;
			this.deleteText = barcode;
		},
		voidConfirm({ uuid, barcode }) {
			this.voidDialog = true;
			this.voidUUID = uuid;
			this.voidText = barcode;
		},
		getValue(td, key) {
			const key_array = key.split(".");
			if (key_array.length > 1) {
				if (key_array[3]) {
					return td[key_array[0]][key_array[1]][key_array[2]][key_array[3]];
				}
				if (key_array[2]) {
					return td[key_array[0]][key_array[1]][key_array[2]];
				}
				if (key_array[1]) {
					return td[key_array[0]][key_array[1]];
				}
				if (key_array[0]) {
					return td[key_array[0]];
				}
			}
			return td[key] || "-";
		},
		updateSelected() {
			if (this.selectedRows && isArray(this.selectedRows)) {
				const selectedRowsLength = toSafeInteger(this.selectedRows.length);
				const tbodyLength = toSafeInteger(this.tbody.length);
				if (selectedRowsLength > 0) {
					if (!this.checkbox) {
						this.checkbox = true;
					}
					this.indeterminate = false;
					if (selectedRowsLength != tbodyLength) {
						this.indeterminate = true;
					}
				} else {
					this.selectedRows = [];
					this.checkbox = false;
					this.indeterminate = false;
				}
				this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
			} else {
				this.$store.commit(SET_SELECTED_ROWS, []);
			}
		},
		selectAll() {
			this.selectedRows = [];
			if (this.checkbox) {
				for (let i = 0; i < this.tbody.length; i++) {
					this.selectedRows.push(this.tbody[i].uuid);
				}
			}
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		},
		createCheckInOut(data) {
			this.$emit("createCheckInOut", data);
		},
		is_edit_disabled(row) {
			if (this.type == "invoice") {
				if (row.is_approved == 1) {
					return true;
				}
			}
			if (this.type == "service") {
				if (row.status != 3) {
					return true;
				}
			}
			if (this.type == "incharge-officer") {
				if (row.is_resigned == 1) {
					return true;
				}
			}
			return false;
		},
		is_checkin(row) {
			if (this.type == "checkout") {
				if (row.inventory > 0) {
					return false;
				}
			}
			return true;
		},
	},
	components: {
		Chip,
		Status,
		Dialog,
		ShowValue,
		ImageTemplate,
		TableDateTime,
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"currentPage",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
	},
};
</script>
